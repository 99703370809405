<template>
  <span class="search-cover fs-16 float-right" :class="{'search-blue': state === 'plot'}">
    <b-icon icon="search"></b-icon>
    <router-link :to="{name: 'favourite', params:{plotId: $route.params.plotId}, query:{plan: $route.query.plan, coords: $route.query.coords, meter_sq: $route.query.meter_sq, city_id: $route.query.city_id}}" v-if="state === 'plot'"><img :src="save" /></router-link>
    <input :class="{'favourite': size, 'plot': plot}" class="text-right" type="text" v-model="searchQuery" @click="showNav" @blur="hideNav" />
    <img v-if="state != 'plot' && state != 'favourite' && state != 'feedback' && state != 'city'" :src="logo" class="float-right" :width="width" >
    <router-link class="fc-97" :to="{name: 'plot', params: {plotNo: $route.params.plotId}, query:{plan: $route.query.plan, coords: $route.query.coords, city_id: $route.query.city_id}}"><b-icon  scale="1.5" v-if="state === 'favourite' || state === 'feedback'" icon="x"></b-icon></router-link>
    <b-icon class="cursor-pointer" scale="1.5" v-if="state === 'plot' || state === 'city'" @click="minNav" icon="arrow-right"></b-icon>
  </span>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'Search',
  props: {
    state: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      logo: require("@/assets/logo.svg"),
      save: require("@/assets/images/icons/save.svg"),
      width: "28px",
      searchQuery: ""
    }
  },
  watch: {
    searchQuery() {
      if (this.$route.name === "city")
         this.filterPlans()
    }
  },
  computed: {
    size() {
      if(this.state === "favourite" || this.state === "feedback") return true
      return false
    },
    plot() {
      if(this.state === "plot")
        return true
      return false
    }
  },
  components: {

  },
  methods: {
    filterPlans: _.debounce(function() {
      this.searchPlans()
    }, 500),
    showNav() {
      this.$emit("switchNav", true)
    },
    hideNav() {
      this.$emit("switchNav", false)
    },
    minNav() {
      this.$emit("minNav")
    },
    searchPlans() {
      let loader = this.$loading.show({opacity: 0.9})
      let payload = {
        meta: {
          page: 1,
          paginate: 30,
          city: this.$route.params.cityId,
          q: this.searchQuery
        }
      }
      if (payload.meta.q == "")
        delete payload.meta.q

      this.$store.dispatch("plan/fetchPlans", payload)
      .then(() => {
        loader.hide()
      })
    }
  }
}
</script>
