<template>
<div>
<b-modal centered id="modal" title="" @close="removeLoginQuery" :hide-footer=true content-class="signup-content" header-class="signup-header">
   <h1 class="text-right mb-4">معلوماتي الشخصية</h1>
   <p class="text-right float-right clearfix"><span class="width-70">ستصلك رسالة نصية تحتوي على أربعة أرقام.الرجاء ادخال الرقم للتحقق من رقم الجوال</span></p>
   <ValidationObserver v-slot="{ handleSubmit, invalid }">
   <b-form dir="rtl" @submit.prevent="handleSubmit(onSubmit)" ref="form">
   <ValidationProvider name="first_name" rules="required">
     <b-form-group slot-scope="{ errors }" class="text-right" id="first_name-group" label="الاسم الأول" label-for="first_name">
       <b-form-input id="first_name" v-model="form.first_name" type="text" required></b-form-input>
       <p>{{ errors[0] }}</p>
     </b-form-group>
    </ValidationProvider>
    <ValidationProvider name="last_name" rules="required">
     <b-form-group slot-scope="{ errors }" class="text-right" id="last_name-group" label="اسم العائلة" label-for="last_name">
       <b-form-input id="last_name" v-model="form.last_name" type="text" required></b-form-input>
       <p>{{ errors[0] }}</p>
     </b-form-group>
    </ValidationProvider>
    <ValidationProvider name="email" rules="required|email">
     <b-form-group slot-scope="{ errors }" class="text-right" id="email-group" label="البريد الالكتروني" label-for="email">
       <b-form-input id="email" v-model="form.email" type="email" required></b-form-input>
       <p>{{ errors[0] }}</p>
     </b-form-group>
     </ValidationProvider>
     <ValidationProvider name="phone" rules="required">
      <b-form-group slot-scope="{ errors }" class="text-right" id="phone-group" label="جوال" label-for="phone">
        <b-form-input dir="ltr" class="text-right" id="phone" v-model="form.phone" type="text" required></b-form-input>
        <p>{{ errors[0] }}</p>
      </b-form-group>
      </ValidationProvider>
      <div class="text-right mb-4" dir="rtl">
      <b-form-checkbox dir="rtl" class="text-right d-inline" id="accepted" v-model="accepted" name="accepted" value="accepted" unchecked-value="not_accepted">
     </b-form-checkbox>
         <!--
             <router-link dir="" class="mr-2" :to="{name: 'privacy'}">اوافق على الشروط والأحكام
        </router-link> -->
                 
        <a class="mr-2 cursor-pointer" @click="goToPrivacy">اوافق على الشروط والأحكام
        </a>
     </div>
     <b-button type="submit" :disabled="invalid || !acceptedUser" class="text-center note-sub-btn" size="lg" block variant="success">تسجيل الدخول</b-button>
  </b-form>
  </ValidationObserver>
</b-modal>

<b-modal no-close-on-esc no-close-on-backdrop centered id="verify_phone" title="" @close="removeLoginQuery" :hide-footer=true content-class="signup-content" header-class="signup-header">
   <h1 class="text-right mb-4">سجل دخول برقم الجوال</h1>
   <p class="text-right float-right clearfix"><span class="width-70">تسجيل الدخول مسموح برقم جوال سعودي فقط
للتأكد من تواجد المستخدم في المملكة العربية
السعودية.</span></p>
   <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
   <b-form @submit.prevent="handleSubmit(verifyPhone)">
      <ValidationProvider rules="required|min:9">
        <b-form-group class="font-sfns" slot-scope="{ errors }" id="phone-group" label-for="phone">
          <vue-tel-input v-on:country-changed="countryChanged" wrapperClasses="phone-wrapper" placeholder="9665122345678" defaultCountry="SA" id="phone" :onlyCountries="['SA']" required v-model="form.phone"></vue-tel-input>
          <p>{{ errors[0] }}</p>
        </b-form-group>
      </ValidationProvider>
     <b-button type="submit" :disabled="invalid" class="text-center note-sub-btn" size="lg" block variant="success">تسجيل الدخول</b-button>
  </b-form>
  </ValidationObserver>
</b-modal>

<b-modal centered id="confirm_otp" title="" @close="removeLoginQuery" :hide-footer=true content-class="signup-content" header-class="signup-header">
   <h1 class="text-right mb-4">التحقق من رقم الجوال</h1>
   <p class="text-right float-right clearfix"><span class="width-70">ستصلك رسالة نصية تحتوي على أربعة أرقام.
الرجاء ادخال الرقم للتحقق من رقم الجوال</span></p>
   <ValidationObserver v-slot="{ handleSubmit, invalid}" ref="form">
   <b-form @submit.prevent="handleSubmit(verifyOtp)">
     <ValidationProvider name="phone" rules="required">
      <b-form-group class="" id="otp-group" label="" label-for="phone">
        <b-form-input maxlength=1 class="otp-input" id="otp_one" v-model="otp.otp_one" type="text" required></b-form-input>
        <b-form-input class="otp-input" maxlength=1 id="otp_two" v-model="otp.otp_two" type="text" required></b-form-input>
        <b-form-input class="otp-input" maxlength=1 id="otp_three" v-model="otp.otp_three" type="text" required></b-form-input>
        <b-form-input class="otp-input" maxlength=1 id="otp_four" v-model="otp.otp_four" type="text" required></b-form-input>
      </b-form-group>
      </ValidationProvider>
<!--     <a v-if="seconds < 1" class="text-right float-right clearfix cursor-pointer" @click="resendOTP"><span>Resend OTP</span></a>-->
     <p v-if="seconds > 0" class="text-right float-right clearfix"><span> اعادة ارسال رمز التحقق خلال {{ seconds }} ثانية </span></p>
     <b-button type="submit" :disabled="invalid" class="text-center note-sub-btn" size="lg" block variant="success">تحقق</b-button>
     <b-button v-if="seconds < 1" class="text-center note-sub-btn" size="lg" block variant="success"
     @click="resendOTP">اعادة ارسال</b-button>
  </b-form>
  </ValidationObserver>
</b-modal>
</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { localize } from 'vee-validate'
import ar from 'vee-validate/dist/locale/ar.json'

localize({
 ar
})
localize ('ar')

export default {
  name: 'Authentication',
  props: {
  },
  data() {
    return {
      seconds: 60,
      phone: '',
      countryCode: '',
      form: {
        email: "",
        first_name: "",
        last_name: "",
        phone: ""
      },
      accepted: "not_accepted",
      otp: {
        otp_one: "",
        otp_two: "",
        otp_three: "",
        otp_four: ""
      }
    }
  },
  created() {
    // this.countDownTimer()
  },
  watch: {
    'otp.otp_one'(newVal) {
      if(newVal != "") {
        document.getElementById("otp_two").focus()
      }
    },
    'otp.otp_two'(newVal) {
      if(newVal != "") {
        document.getElementById("otp_three").focus()
      }
      else {
        document.getElementById("otp_one").focus()
      }
    },
    'otp.otp_three'(newVal) {
      if(newVal != "") {
        document.getElementById("otp_four").focus()
      }
      else {
        document.getElementById("otp_two").focus()
      }
    },
    'otp.otp_four'(newVal) {
      if(newVal == "") {
        document.getElementById("otp_three").focus()
      }
    },
    'form.phone'(newVal) {
      if(newVal.indexOf('+') != -1) {
        this.form.phone = newVal.replace('+', '')
      }
    }
  },
  computed: {
    size() {
      if(this.state === "favourite" || this.state === "feedback") return 29
      return 23
    },
    status() {
      return this.$store.state.auth.status
    },
    acceptedUser() {
      return (this.accepted === "accepted") ? true : false
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  methods: {
    countryChanged(country) {
      this.countryCode = country.dialCode
    },
    countDownTimer() {
      if (this.seconds > 0) {
        setTimeout(() => {
          this.seconds -= 1
          this.countDownTimer()
        }, 1000);
      } else this.seconds = 0;
    },
    resendOTP() {
      this.verifyPhone2()
    },
    onSubmit() {
      let loader = this.$loading.show({opacity: 0.9})
      this.$store.dispatch("auth/register", this.form)
      .then(() => {
        loader.hide()
        this.emptyForm()
        this.$bvModal.hide('modal')
      })
      .catch((err) => {
        loader.hide()
        if (err.data.error.error) {
          err.data.error.message.forEach(message => {
            this.$toast.warning(message)
          })
        }
      })
    },
    goToPrivacy() {
       let routeData = this.$router.resolve({name: 'privacy'})
       window.open(routeData.href, '_blank')
    },
    verifyPhone() {
      let phoneNumber = this.form.phone.startsWith('0') ? this.form.phone.substr(1) : this.form.phone
      let number = this.countryCode + phoneNumber
      let loader = this.$loading.show({opacity: 0.9}); this.phone = number
      console.log(number.split(" ").join(""))
      this.$store.dispatch("auth/verifyPhone", {phone: number.split(" ").join("")})
      .then(() => {
        loader.hide()
        this.emptyForm()
        //this.$toast.success(res.data.message)
        this.$toast.success("تم ارسال رمز التحقق")
        //open otp
        this.$bvModal.hide('verify_phone')
        this.$bvModal.show('confirm_otp')
        this.countDownTimer()
      })
      .catch(() => {
        this.$toast.warning("Something went wrong!")
        loader.hide()
      })
    },
    verifyPhone2() {
      let loader = this.$loading.show({opacity: 0.9});
      this.$store.dispatch("auth/verifyPhone", {phone: this.phone.split(" ").join("")})
        .then(() => {
          loader.hide()
          this.$toast.success("تم ارسال رمز التحقق")
          this.seconds = 60
          this.countDownTimer()
        }).catch(() => {
          this.$toast.warning("Something went wrong!")
          loader.hide()
        })
    },
    verifyOtp() {
      let loader = this.$loading.show({opacity: 0.9})
      let payload = {
        phone: localStorage.getItem("phone"),
        otp: `${this.otp.otp_one}${this.otp.otp_two}${this.otp.otp_three}${this.otp.otp_four}`
      }
      this.$store.dispatch("auth/confirmOtp", payload)
      .then(() => {
        loader.hide()
        this.emptyForm()
        //this.$toast.success(res.data.message)
        this.$bvModal.hide('confirm_otp')
        if(this.status === "unregistered") {
          this.$bvModal.show('modal')
        }
        else {
          this.$toast.success("تم تسجيل الدخول بنجاح")
        }
      })
      .catch(() => {
        loader.hide()
        //this.$toast.warning(err.response.data.message)
        this.$toast.warning("رمز التحقق خاطئ")
      })
    },
    emptyForm() {
      this.form.email = this.form.first_name = this.form.last_name = this.form.phone = ""
      this.otp.otp_one = this.otp.otp_two = this.otp.otp_three = this.otp.otp_four = ""
      this.$nextTick(() => {
        //this.$refs.form.reset()
      })
    },
    removeLoginQuery() {
      if (this.$route.query.login != undefined) {
        this.$router.push({path: this.$route.path})
      }
    }
  }
}
</script>
<style>
  a {
    color: #333;
  }
  a:hover {
    color: #333;
  }
</style>
