<template>
  <div class="plan-favourite">
    <b-button :to="{'name': 'plan-favourite', params: {'planId': planId}, query:{'plan': $route.query.plan, 'city': $route.params.cityId}}" :class="{'action-color': bookmarked, 'primary-color': saved}" pill><img :src="saveIcon">حفظ المخطط</b-button>
  </div>
</template>

<script>
export default {
  name: 'PlanFavourite',
  data() {
    return {
    }
  },
  computed: {
    bookmarked() {
      return false
    },
    saved() {
      if (this.$route.name === 'saved')
        return true
      return false
    },
    planId() {
      let planNo = this.$route.query.plan
      let plans = this.$store.state.plan.plans
      let planIndex = plans.findIndex(p => p.planNo === planNo)
      if(planIndex != -1)
        return plans[planIndex]["id"]
      return 1
    },
    saveIcon() {
      if (this.bookmarked || this.saved)
        return require("@/assets/images/icons/bookmark.svg")
      return require("@/assets/images/icons/bookmark_white.svg")
    },
  },
  components: {

  },
  methods: {

  }
}
</script>
