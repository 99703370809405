<template>
  <div id="m-map-box">
    <transition name="search-fade">
      <div v-show="showNavigation" class="search-nav">
        <router-view></router-view>
      </div>
    </transition>
    <mapbox
        :access-token="accessToken"
        :map-options="options"
        :nav-control="control"
        @map-zoomend="zoomend"
        @map-click:class-color="clicked"
        @map-mouseenter:class-color="mouseEntered"
        @map-mouseleave:class-color="mouseLeft"
        @map-load="mapLoaded"
        @map-init="mapInitialized"
    />
    <plan-favourite v-if="showPlanFavourite"/>
    <footer-map-actions/>
    <div v-if="searchState != 'home'" class="m-map-back">
      <router-link :to="{name: 'home'}">
        <b-icon icon="arrow-left-circle-fill"></b-icon>
      </router-link>
    </div>
    <div v-if="searchState == 'home'" class="m-map-back">
      <router-link :to="{name: 'landing'}">
        <b-icon icon="arrow-left-circle-fill"></b-icon>
      </router-link>
    </div>
    <div class='map-actions2'>
      <search :state="searchState" @minNav="minNav" @switchNav="switchNav"/>
      <section class="menu-section">
        <div class="menu-box" @click="toggleMenu" v-if="!menu">
          <b-icon icon="layers"></b-icon>
        </div>
        <div class="new-div-wrap" v-if="menu">
          <div class="new-div">
            <div class="menu-title">
              <span>طريقة العرض</span>
              <b-icon icon="x-octagon" @click="toggleMenu" style="cursor: pointer;"></b-icon>
            </div>
            <!--            <b-checkbox size="lg" switch @change="switchStyle"></b-checkbox>-->
            <div class="inner-div">
              <div
                class="box-div bottom"
                @click="switchStyle(1)"
                v-bind:class="{ selected: styleInteger === 1 }"
                v-if="deployment !== 'production'"
              >
                <span>سعر المتر المربع</span>
              </div>
              <div class="box-div" @click="switchStyle(2)" v-bind:class="{ selected: styleInteger === 2 }">
                <span>تصنيف العقار</span>
              </div>
              <div
                class="box-div"
                @click="switchStyle(3)"
                v-bind:class="{ selected: styleInteger === 3 }"
                v-if="deployment !== 'production'"
              >
                <span>{{$t('test_style')}}</span>
              </div>
            </div>
            <span class="legend-span"> دليل الألوان </span>
            <div class="legend-wrap" v-if="styleInteger === 1">
              <div class="color-box">
                <span class="legend-title">0-500</span>
                <div class="each-color" style="background-color: #d9e1f2;"></div>
              </div>
              <div class="color-box">
                <span class="legend-title">500-2k</span>
                <div class="each-color" style="background-color: #b4c6e7;"></div>
              </div>
              <div class="color-box">
                <span class="legend-title">2k-5k</span>
                <div class="each-color" style="background-color: #8faadc;"></div>
              </div>
              <div class="color-box">
                <span class="legend-title">5k-10k</span>
                <div class="each-color" style="background-color: #305496;"></div>
              </div>
              <div class="color-box">
                <span class="legend-title">10k+</span>
                <div class="each-color" style="background-color: #203764;"></div>
              </div>
            </div>
            <div class="color-box-3" v-if="styleInteger === 1">
              <span class="legend-title">لاتوجد معلومات كافية</span>
              <div class="each-color" style="background-color: #f7a6a6;"></div>
            </div>
            <div class="legend-two-wrap" v-if="styleInteger === 2 || styleInteger === 0">
              <div class="color-box-2">
                <span class="legend-title">سكني</span>
                <div class="each-color" style="background-color: #9edfff;"></div>
              </div>
              <div class="color-box-2">
                <span class="legend-title">تجاري</span>
                <div class="each-color" style="background-color: #001b4d;"></div>
              </div>
              <div class="color-box-2">
                <span class="legend-title">لاتوجد معلومات كافية</span>
                <div class="each-color" style="background-color: #f7a6a6;"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="m-map-actions">
      <b-container>
        <b-row>
          <b-col>
            <map-actions/>
          </b-col>

        </b-row>
      </b-container>
    </div>
    <authentication v-if="!authenticated"/>
    <div class="beta-box" v-if="this.beta">
      <div class="beta">
        <a @click="toggleBeta">x</a>
        <p>نسخة تجريبية</p>
      </div>
    </div>
  </div>
</template>


<script>
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import Mapbox from 'mapbox-gl-vue'
import Search from '@/components/Search.vue'
import MapActions from '@/components/MapActions.vue'
import Authentication from '@/components/Authentication.vue'
import FooterMapActions from '@/components/FooterMapActions.vue'
import PlanFavourite from '@/components/PlanFavourite.vue'

// New push 2
export default {
  title: "Mokhatat Web",

  data() {
    return {
      logo: require("@/assets/logo.svg"),
      logo_white: require("@/assets/logo2.svg"),
      home: require('@/assets/images/icons/home.svg'),
      showNavigation: false,
      map: {},
      map_initialized: {},
      set_zoom: false,
      map_loaded: false,
      beta: true,
      styleInteger: 0,
      menu: false,
      selectedStyle: null,
      deployment: process.env.NODE_ENV
    }
  },
  components: {
    Mapbox,
    Search,
    MapActions,
    Authentication,
    FooterMapActions,
    PlanFavourite
  },
  watch: {
    '$route.meta.sticky'(val) {
      this.showNavigation = val
    },
    '$route.query'() {
      if (this.$route.query.login != undefined && this.$route.query.login == "true") {
        this.openLogin()
        return
      }
      if (this.$route.query.plan != undefined && this.$route.name === 'city') {
        let plan = this.$route.query.plan
        let city = this.$route.params.cityId
        let cityMapUrl = this.$route.params.plan['city']['mapbox_style_url'];
        let coords = this.$route.params.plan['points'];
        let cityCoords = this.$route.params.plan['city']['coords'];
        if (cityMapUrl) {
          this.map_initialized.setStyle(cityMapUrl ? cityMapUrl : this.selectedStyle)
        }

        if (coords) {
          let center = coords.map((point) => {
            return parseFloat(point)
          })
          this.map.flyTo({center: center, zoom: 16})
        } else if (cityCoords) {
          let center = cityCoords.split(",").map((point) => {
            return parseFloat(point)
          })
          this.map.flyTo({center: center, zoom: 11})
        }

        this.fetchPlan(city, plan)
      }
      if (this.$route.query.coords != undefined) {
        this.useCoords()
      }
    },
    '$store.state.city.city'(obj) {
      if (obj.mapbox_style_url != undefined) {
        let temp = this.map_initialized
        this.map_initialized.setStyle(this.selectedStyle ? this.selectedStyle : obj.mapbox_style_url)
        this.map_initialized.flyTo({
          center: obj.coords.split(",").map((coord) => {
            return parseFloat(coord)
          }), zoom: 11
        })
        this.map_initialized = temp
      }
    },
    '$store.state.plan.plot'(obj) {
      let style = this.$store.state.options.style
      if (obj.city != undefined) {
        if (obj.city[0]["mapbox_style_url"] != style) {
          let temp = this.map_initialized
          this.map_initialized.setStyle(this.selectedStyle ? this.selectedStyle : obj.city[0]["mapbox_style_url"])
          if (this.map_loaded) {
            this.map.flyTo({
              center: this.$route.query.coords.split(",").map((coord) => {
                return parseFloat(coord)
              }), zoom: 17
            })
          }
          this.map_initialized = temp
        }
      }
    }
  },
  computed: {
    options() {
      return this.$store.state.options
    },
    accessToken() {
      return this.$store.state.accessToken
    },
    control() {
      return this.$store.state.control
    },
    searchState() {
      return this.$route.name
    },
    authenticated() {
      return this.$store.state.AppActiveUser.first_name != undefined
    },
    showPlanFavourite() {
      if (this.$route.name === "city" && this.$route.query.plan != undefined)
        return true
      return false
    },
    views() {
      return this.$store.state.plan.views
    }
  },
  methods: {
    switchNav(value) {
      if (!this.authenticated && this.$route == null) {
        this.$bvModal.show('verify_phone')
        return
      }
      if (this.$route.meta.sticky === true && !this.showPlanFavourite) {
        this.showNavigation = true
        return
      }
      this.showNavigation = value
    },
    minNav() {
      this.showNavigation = false
    },
    zoomend(map) {
      let zoom = map.getZoom()
      this.$store.dispatch("changeZoom", zoom)
    },
    clicked(map, e) {
      //map.queryRenderedFeatures(e.point)[0].properties
      let properties = map.queryRenderedFeatures(e.point)[0].properties

      if (!this.authenticated && (this.views >= 5)) {
        this.$bvModal.show('verify_phone')
        return
      }
      const plotNo = e.features ? e.features[0]["properties"]["Plot_No"] : properties && properties.Plot_No
      const planNo = e.features ? e.features[0]["properties"]["Plan_No"] : properties && properties.Plan_No
      const meter_sq = e.features ? e.features[0]["properties"]["Area"] : properties && properties.Area
      const coords = `${e.lngLat.lng},${e.lngLat.lat}`
      const city_id = e.features ? e.features[0]["properties"]["city_id"] : properties && properties.city_id

      let payload = {
        properties: e.features ? e.features[0]["properties"] : properties,
        lngLat: e.lngLat
      }


      this.$store.dispatch("plan/addPlotDetails", payload)
      // const plotId = this.$route.params.plotId
      // const plan = this.$route.query.plan
      // if (plotNo == plotId && planNo == plan) {
      //   this.showNavigation = true
      //   return
      // }
      // if (plotNo && planNo) {
      this.showNavigation = true
      //   return
      // }
      this.$store.dispatch("plan/updateViews")
      this.$router.push({
        name: 'plot',
        params: {plotId: plotNo},
        query: {plan: planNo, meter_sq: meter_sq, coords: coords, city_id: city_id}
      })

    },
    mouseEntered(map) {
      map.getCanvas().style.cursor = 'pointer'
    },
    mouseLeft(map) {
      map.getCanvas().style.cursor = ''
    },
    mapLoaded(map) {
      this.map = map
      var language = new MapboxLanguage({
        defaultLanguage: 'ar'
      })
      map.addControl(language)

      this.map_loaded = true
      if (this.set_zoom) {
        this.zoomTo(map)
        this.set_zoom = false
      }
    },
    mapInitialized(map) {

      let RTLStatus = window.mapboxgl.getRTLTextPluginStatus()

      if (RTLStatus != "loaded") {
        window.mapboxgl.setRTLTextPlugin('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js')

      }

      this.map_initialized = map
    },
    openLogin() {
      this.$bvModal.show('verify_phone')
    },
    plan(val) {
      let planIndex = this.$store.state.plan.plans.findIndex(plan => plan.id === val)
      return this.$store.state.plan.plans[planIndex]
    },
    center(val) {
      let planIndex = this.$store.state.plan.plans.findIndex(plan => plan.planNo === val)
      let plan = this.$store.state.plan.plans[planIndex]
      let center = plan.points
      if (center == null) {
        center = plan.city.coords.split(",").map((coord) => {
          return parseFloat(coord)
        })
      } else {
        center = center.map((coord) => {
          return parseFloat(coord)
        })
      }
      return center
    },
    fetchPlan(city, plan) {
      let payload = {
        meta: {
          city_id: city,
          q: plan
        }
      }
      let loader = this.$loading.show({opacity: 0.9})
      this.$store.dispatch("plan/fetchPlan", payload)
          .then(() => {
            loader.hide()
          })
          .catch(() => {
            loader.hide()
          })
    },
    useCoords() {
      let center = this.$route.query.coords.split(",").map((point) => {
        return parseFloat(point)
      })
      this.map.flyTo({center: center})
    },
    zoomTo(map) {
      let center = this.$route.query.coords.split(",").map((point) => {
        return parseFloat(point)
      })
      //let us try to set style here
      let plot = this.$store.state.plan.plot
      if (plot.city == undefined) {
        //fetchCity
        let style = this.$store.state.options.style
        this.$store.dispatch("city/fetchCity", this.$route.query.city_id)
            .then((res) => {
              if (style != res.data.data.mapbox_style_url) {
                let temp = this.map_initialized
                this.map_initialized.setStyle(this.selectedStyle ? this.selectedStyle : res.data.data.mapbox_style_url)
                map.flyTo({center: center, zoom: 17})
                this.map_initialized = temp
                return
              }
            })
      }
      map.flyTo({center: center, zoom: 17})
    },
    toggleBeta() {
      this.beta = !this.beta
    },
    switchStyle(check) {
      this.styleInteger = check
      let url = this.$store.state.city.cities.length && this.$store.state.city.cities[1].meter_sq_style_url
      if (check === 1) {
        this.map_initialized.setStyle(url ? url : 'mapbox://styles/mokhatat/ckrt2rrci10f317plw8haj2us')
        this.selectedStyle = url ? url : 'mapbox://styles/mokhatat/ckrt2rrci10f317plw8haj2us'
      } else if (check === 2) {
        this.selectedStyle = 'mapbox://styles/mokhatat/ckl1ee4010e0r18mx2plk1mqz'
        this.map_initialized.setStyle('mapbox://styles/mokhatat/ckl1ee4010e0r18mx2plk1mqz')
      } else {
        this.selectedStyle = 'https://api.maptiler.com/maps/4341c0a2-8ea1-4d8f-9ecd-9cf20870d026/style.json?key=2hA5DyubM78yzv91vWqv'
        this.map_initialized.setStyle('https://api.maptiler.com/maps/4341c0a2-8ea1-4d8f-9ecd-9cf20870d026/style.json?key=2hA5DyubM78yzv91vWqv')
      }
    },
    toggleMenu() {
      this.menu = !this.menu
    }
  },
  created() {
    this.showNavigation = this.$route.meta.sticky
    if (this.$route.query.coords != undefined) {
      this.set_zoom = true
    }

  },
  mounted() {
    if (this.$route.query.login == "true") {
      this.openLogin()
    }

  },
  updated() {
  }
}
</script>

<style lang="css">
 .menu-section {
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   align-items: flex-end;
   transition: 0.3s;
 }
  .new-div-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 15px;
    margin-right: 10px;
    transition: 0.3s;
  }
  .new-div {
    background: rgba(255,255,255,0.8);
    width: 280px;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    font-size: 14px;
    color: #5C5C5C;
    font-weight: bold;
    font-family: "Apple SD Gothic Neo";
    transition: 0.3s;
  }
  .inner-div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 3px;
    transition: 0.3s;
  }
  .box-div {
    margin-bottom: 14px;
    background-color: rgba(255,255,255,1);
    color: #5C5C5C;
    border-radius: 5px;
    font-size: 11px;
    width: 48%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: "Apple SD Gothic Neo";
    cursor: pointer;
    transition: 0.3s;
  }
  .box-div .bottom {
    margin-bottom: 10px;
  }
  .new-div .menu-title span {
    margin-right: 8px;
    transition: 0.3s;
  }
  .selected {
    background-color: #001C4F;
    color: #fff;
    transition: 0.3s;
  }
  .legend-span {
    margin-top: 15px;
    margin-bottom: 5px;
    display: block;
    transition: 0.3s;
    text-align: right;
    margin-right: 8px;
  }
  .legend-wrap {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    transition: 0.3s;
  }
 .legend-wrap>* {
   flex: 0 0 29%;
   margin: 5px
 }
 .legend-two-wrap {
   display: flex;
   width: 100%;
   flex-wrap: wrap;
   flex-direction: row-reverse;
   transition: 0.3s;
 }
 .legend-two-wrap>* {
   margin: 5px
 }
  .color-box {
    border: 1px solid hsla(0,0%,100%,.7);
    height: 35px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }
  .color-box-2 {
    border: 1px solid hsla(0,0%,100%,.7);
    height: 35px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    padding: 0 10px;
  }
  .color-box-3 {
    border: 1px solid hsla(0,0%,100%,.7);
    height: 35px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    transition: 0.3s;
    padding: 0 10px;
  }
  .each-color {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: 0.3s;
    margin-left: 5px;
  }
  .legend-title {
    font-size: 12px;
    margin-top: 1px;
    transition: 0.3s;
  }
  .menu-box {
    height: 40px;
    width: 40px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 15px;
    margin-right: 10px;
    transition: 0.3s;
  }
  .menu-title {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    display: flex;
    transition: 0.3s;
    margin-bottom: 5px;
  }
</style>
